export const theme = {
  colors: {
    background: '#1a1b26',
    surface: '#24283b',
    primary: '#7aa2f7',
    primaryGradient: 'linear-gradient(135deg, #7aa2f7 0%, #bb9af7 100%)',
    text: '#c0caf5',
    textSecondary: '#9aa5ce',
    accent: '#bb9af7',
    error: '#f7768e',
    success: '#9ece6a'
  },
  spacing: {
    navHeight: '70px',
    sectionPadding: '80px 24px',
    gap: {
      small: '16px',
      medium: '24px',
      large: '32px'
    }
  },
  maxWidth: '1200px',
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '12px'
  },
  shadows: {
    card: '0 4px 20px rgba(0, 0, 0, 0.25)',
    hover: '0 8px 30px rgba(0, 0, 0, 0.3)'
  }
}; 